import Vue from 'vue'
import App from './App.vue'
import AOS from 'aos'
import VueAnalytics from 'vue-ua'

Vue.config.productionTip = false
AOS.init();

import './assets/scss/app.scss'
import 'aos/dist/aos.css'

Vue.component('welcome', require('@/components/welcome').default);
Vue.component('intro', require('@/components/intro').default);
Vue.component('terms', require('@/components/terms').default);
Vue.component('gameover', require('@/components/gameover').default);
Vue.component('leaderboard', require('@/components/leaderboard').default);
Vue.component('game.index', require('@/game/index').default);

Vue.use(VueAnalytics, {
  appName: 'Virus Slayer',
  appVersion: '1.0',
  trackingId: 'UA-140038427-1',
  debug: true,
  trackPage: true
})

Vue.prototype.$userSession = { 
  sessid: Math.floor(Math.random() * 10001000),
  needAgeCheck: true,
  audio: true,
  form: {
      name: null,
      email: null
  }
}

new Vue({
  render: h => h(App),
}).$mount('#app')
