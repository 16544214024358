<template>

    <div class="bg-primary bg-virus w-full h-full flex flex-col items-center">

		<div class="relative mt-6">
			<img src="@/assets/images/monster_1.png" data-aos="fade-up" class="absolute w-32 -right-6 -top-5">
			<img src="@/assets/images/virus_slayer_logo.png" data-aos="fade-down" class="w-64" alt="logo">
		</div>

        <div v-if="topScorer && !forfun" class="flex flex-col items-center justify-center font-6809 mt-10">
            <img src="@/assets/images/virus_6.png" class="w-32">
            <h1 class="text-4xl mt-2">Top Scorer</h1>
            <h2 class="text-2xl my-4 text-center">You Scored <br> {{ data.score }} </h2>
            <h3 class="text-xl text-center">Position No. {{ rank }} <br> on the leaderboard</h3>
			<nav class="m-6 flex">
				<button @click="showPlay=true" class="btn text-base font-light mr-1 animate-elastic">Play Again</button>
				<button @click="goTo('game.index')" class="btn text-base font-light ml-1 animate-elastic">Leaderboard</button>
			</nav>
        </div>

        <div v-else-if="!topScorer && !forfun" class="flex flex-col items-center justify-center font-6809 mt-10">
            <img src="@/assets/images/virus_7.png" class="w-32">
            <h1 class="text-4xl mt-2">Game Over</h1>
            <h2 class="text-2xl my-4 text-center">You Scored <br> {{ data.score }} </h2>
            <h3 class="text-xl text-center">Position No. {{ rank }} <br> on the leaderboard</h3>
			<nav class="m-6 flex">
				<button @click="goTo('game.index')" class="btn text-base font-light mr-1 animate-elastic">Play Again</button>
				<button @click="goTo('leaderboard')" class="btn text-base font-light ml-1 animate-elastic">Leaderboard</button>
			</nav>
        </div>

        <div v-else class="flex flex-col items-center justify-center font-6809 mt-10">
            <img src="@/assets/images/virus_7.png" class="w-32">
            <h1 class="text-4xl mt-2">Game Over</h1>
            <h2 class="text-2xl my-4 text-center">You Scored <br> {{ data.score }} </h2>
			<nav class="m-6 flex">
				<button @click="goTo('game.index')" class="btn text-base font-light mr-2 animate-elastic">Play Again</button>
				<button @click="goTo('leaderboard')" class="btn text-base font-light ml-2 animate-elastic">Leaderboard</button>
			</nav>
        </div>

        <footer class="flex justify-between border-t w-full mt-auto text-xs p-2">
			<img src="@/assets/images/baggl_logo.png" class="h-4">
			<span>barter. haggle. baggl.com</span>
		</footer>

    </div>

</template>

<script>
import api from '../api'

export default {
    props: ['data'],
	data(){
		return{
			rank: 0,
            topScorer: 0,
            loading: true,
            forfun: true
		}
	},
    beforeMount(){

        let id = this.$userSession.sessid;

        if(this.$userSession.form.name && this.$userSession.form.email){
            this.forfun = false
            api.submit({ 
                score: this.data.score, 
                name: this.$userSession.form.name,
                email: this.$userSession.form.email,
                id: id, 
                token: id * (Date.now() / 1000) 
            }).then(r => {
                this.rank = r.data.rank
                if(this.rank < 10) this.topScorer = true
            });
        }

    },

	mounted(){
		this.$ua.trackView('gameover', true);
	},

	methods: {
        goTo(component){
            this.$emit('upcomponent', component)
        }
	}
}
</script>