import axios from 'axios'

export default{

    config: {
        headers: { Authorization: `Bearer dWIiOiI-MjM0N1Y3DkwIiw-bmFt` }
    },

	submit(obj)
	{
		return axios.post('https://xhr.appetitecreative.com/appetite/v2/game/baggl-april-2020/submit', obj, this.config);
	},

	leaderboard(obj)
	{
		return axios.post('https://xhr.appetitecreative.com/appetite/v2/game/baggl-april-2020/results', obj, this.config);
	}
}