<template>

    <div class="bg-primary bg-virus w-full h-full flex flex-col justify-center items-center">
		<div class="relative mt-6">
			<img src="@/assets/images/monster_1.png" data-aos="fade-up" class="absolute w-32 -right-6 -top-5">
			<img src="@/assets/images/virus_slayer_logo.png" data-aos="fade-down" class="w-64" alt="logo">
		</div>

        <div class="mx-4 w-full">
            <div class="bg-secondary h-2 mx-10 mt-10"></div>
        </div>
        
        <button @click="start" class="btn text-base font-light mt-10 animate-elastic">Start</button>
    </div>

</template>

<script>
export default {
    methods: {
        start(){
            this.$emit('upaudio', { volume: 0.25, start: true })
        }
    }
}
</script>