<template>
	<div class="w-full h-full">
		<div class="w-full h-full bg-primary bg-virus bg-animate flex flex-col items-center">

		<div class="relative mt-6">
			<img src="@/assets/images/monster_1.png" data-aos="fade-up" class="absolute w-32 -right-6 -top-5">
			<img src="@/assets/images/virus_slayer_logo.png" data-aos="fade-down" class="w-64" alt="logo">
		</div>

		<img data-aos="fade-up" data-aos-delay="300" src="@/assets/images/top_dont_be_beaten_text.png" class="w-64 mt-4">

		<transition name="component-fade" mode="out-in">
			<div v-if="!showPlay" class="relative flex items-center mt-4 px-2">
				<img src="@/assets/images/monster_3.png" class="w-6/12 ml-2">
				<img src="@/assets/images/mystery_prizes_text.png" class="absolute right-6 top-3 w-1/2">
			</div>
		</transition>

		<transition name="component-fade" mode="out-in">
			<nav class="m-6 flex" v-if="!showPlay">
				<button @click="clickPlayToWin" class="btn text-base font-light mr-1 animate-elastic">Play To Win</button>
				<button @click="clickPlayForFun" class="btn text-base font-light ml-1 animate-elastic">Play For Fun</button>
			</nav>
		</transition>

		<transition name="zoom" mode="out-in">
		<div v-if="showPlay==false" key="top" class="w-full mx-4 text-center">
			<h1 class="text-2xl uppercase font-6809">Top Scorers</h1>
			<ul v-if="!loading" class="bg-secondary bg-opacity-30 rounded-sm font-6809 mx-4 p-2">
                <li v-for="user in leaderboard" :key="user.id" class="flex justify-between w-full text-sm">
                    <p class="w-1/4 text-left">{{ user.name }}</p>
                    <p class="w-1/4">..........</p>
                    <span class="w-1/4 text-right">{{ user.score }}</span>
                </li>
			</ul>
            <p v-else class="bg-secondary bg-opacity-30 text-sm rounded-sm font-6809 mx-4 p-2 animate-pulse">
				<svg class="animate-spin mx-auto h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
			</p>
			<p class="text-right text-xs mt-1 mr-4 font-6809" @click="goTo('leaderboard')">see all scores >></p>
		</div>
		<div v-else key="form" class="w-full mx-4 mt-auto text-center">
			
			<formSignup @formsubmitted="submitForm" @goback="showPlay=false" />

		</div>
		</transition>

		<p class="text-xxs p-3 text-center mt-auto">* The top 10 scorers will each win one of a selection of business related prizes. Competition ends 30th June 2021. <span class="underline" @click="goTo('terms')">T&Cs apply</span>.</p>

		<footer class="flex justify-between border-t w-full text-xxs p-2">
			<img src="@/assets/images/baggl_logo.png" class="h-4">
			<span>barter. haggle. baggl.com</span>
		</footer>

		<transition name="zoom" mode="out-in">
			<div v-if="popSound" class="absolute z-20 bg-primary bg-opacity-60 inset-0 flex justify-center items-center">
				<div v-if="legalAge" class="bg-white rounded-md p-5 mx-5 flex flex-col items-center text-secondary">
					<h1 class="font-6809 text-3xl">Welcome!</h1>
					<p class="text-center mt-2 mb-1">Are you over 18?</p>
					<nav>
						<button @click="enableSound(true)" class="btn text-xl font-light mt-4 animate-elastic">Yes</button>
						<button @click="legalAge=false" class="btn text-xl font-light mt-4 animate-elastic">No</button>
					</nav>
					<p class="text-xs opacity-50 mt-6">version 1.1.0</p>
				</div>
				<div v-else class="bg-white rounded-md p-5 mx-5 flex flex-col items-center text-secondary">
					<h1 class="font-6809 text-3xl">Oh dear!</h1>
					<p class="text-center my-3">It seems that you're too young too play!</p>
					<button @click="goBack" class="btn text-xl font-light mt-4 animate-elastic">Go Back</button>
				</div>
			</div>
		</transition>

		</div>
	</div>
</template>

<script>
import api from '../api'
import formSignup from '../components/formSignup'

export default {
	data(){
		return{
			showPlay: false,
			popSound: true,
			legalAge: true,
			loading: true,
			leaderboard: []
		}
	},

	components: { formSignup },
	
	beforeMount(){
		api.leaderboard({ key: 'MkThw24PDVcQJcpTuRNb1IOMAi87neOd', limit: 1 }).then(r => {
			this.leaderboard = r.data.results
			this.loading = false
		})
		this.popSound = this.$userSession.needAgeCheck
	},

	mounted(){
		this.$ua.trackView('intro', true);
	},

	methods: {
        goTo(component){
            this.$emit('upcomponent', component)
        },
		goBack(){
			window.history.back();
		},
		clickPlayToWin(){
			if(this.$userSession.form.name && this.$userSession.form.name.length > 0){
				this.goTo('game.index');
				return;
			}
			this.showPlay = 1
		},
		clickPlayForFun(){
			this.$userSession.form.name = null;
			this.$userSession.form.email = null;
			this.goTo('game.index');
		},
		enableSound(status){
			this.popSound = false
			this.$userSession.needAgeCheck = false
			this.$emit('upaudio', { volume: 0.5, start: status })
		},
		submitForm(){
			this.goTo('game.index');
		}
	}
}
</script>
