<template>
    <div :id="containerId" v-if="downloaded" />
    <div class="h-screen bg-purple text-white flex items-center justify-center" v-else>
        Loading...
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import Phaser from 'phaser'

export default {
    name: 'Game',
    props: ['details'],
    data() 
    {
        return {
            downloaded: false,
            gameInstance: null,
            containerId: 'game_container'
        }
    },

    async mounted() 
    {
        const game = await import('../game')
        this.downloaded = true

        this.$nextTick(() => {

            let em = new Phaser.Events.EventEmitter();

            em.addListener('update-score', v => this.$emit('update-score', v));
            em.addListener('close-game', () => this.$emit('close-game'));

            this.gameInstance = game.launch(this.containerId, em, this.details)
            
        })
    },

    beforeDestroy(){
        this.gameInstance.destroy(true)
    }
}
</script>