<template>
	<div class="w-full h-full">
		<div class="w-full h-full bg-primary bg-virus bg-animate flex flex-col p-8 text-sm pr-20 overflow-y-auto">

            <button @click="goTo('intro')" class="btn text-xs absolute top-5 right-2 font-light mt-4 animate-elastic">x</button>

            <h2 class="text-xl font-bold mb-6 text-left">Virus Slayer Game and Competition Terms</h2>
            <p>By playing the Virus Slayer game (“Game”), the Player (“You”,”Your”) agree to the following terms:</p>
            
            <h4 class="text-lg my-6 font-bold mb-4 text-left">1. Player</h4>
            <ol>
                <li><strong>1.1</strong> You are aged 18yrs+</li>
                <li><strong>1.2</strong> You can ‘Play to win’ entering our ‘top 10 scorer’ competition.</li>
                <li><strong>1.3</strong> You can ‘Play for fun’ without entering our ‘top 10 scorer’ competition.</li>
            </ol>

            <h4 class="text-lg my-6 font-bold mb-4 text-left">2. Competition</h4>
            <ol>
                <li><strong>2.1</strong> You are playing to win one of 10x business related mystery prizes.</li>
                <li><strong>2.2</strong> If you ‘Play to win’ you must be a business owner, employee, contractor or freelancer.</li>
                <li><strong>2.3</strong> The competition starts on 22nd April 2021 and ends 30th June 2021.</li>
                <li><strong>2.4</strong> The business related mystery prizes cannot be redeemed for cash.</li>
                <li><strong>2.5</strong> The winners will be selected based on the top 10 scores recorded until the end of the competition on the 30th June 2021 and will be notified by email.</li>
                <li><strong>2.6</strong> One top-score will be recorded per player, meaning your top score will override your previous score, rather than having multiple top-scores.</li>
                <li><strong>2.7</strong> One competition entry permitted per person.</li>
                <li><strong>2.8</strong> The results will be posted on baggl.com once the competition has been ended.</li>
                <li><strong>2.9</strong> You will not use illicit names or words for your player name. This may result in your score being removed and being disqualified from the competition if you’re playing to win.</li>
            </ol>

            <h4 class="text-lg my-6 font-bold mb-4 text-left">Privacy</h4>
            <p class="py-1">Baggl UK Ltd will:</p>
            <ol>
                <li><strong>3.1</strong> Receive a copy of your email address, which will be managed in accordance with our privacy policy.</li>
                <li><strong>3.2</strong> Send you an email confirming your entry into the competition, at which point you will have the opportunity to opt out of further communications.</li>
                <li><strong>3.3</strong> Contact you to enquire about signing-up to our online marketplace</li>
            </ol>

        </div>
    </div>
</template>

<script>
export default {

	mounted(){
		this.$ua.trackView('terms', true);
	},

    methods: {
        goTo(component){
            this.$emit('upcomponent', component)
        }
    }
}
</script>