<template>

    <div v-if="!startgame" class="bg-primary bg-virus w-full h-full flex flex-col items-center">
		<div class="relative mt-6">
			<img src="@/assets/images/monster_1.png" data-aos="fade-up" class="absolute w-32 -right-6 -top-5">
			<img src="@/assets/images/virus_slayer_logo.png" data-aos="fade-down" class="w-64" alt="logo">
		</div>
        <img data-aos="fade-up" data-aos-delay="300" src="@/assets/images/monster_3.png" class="w-40 my-8">

        <div v-if="levelcomplete" class="text-center">
            <h1 class="font-6809 text-2xl uppercase mb-6">Level <br> <span class="text-6xl">{{ details.level }}</span> <br /> Complete</h1>

            <p v-if="details.level==1" class="text-white text-opacity-80 text-xs font-h-monkey px-12 mb-5">Catch the bad virus, avoiding the white ones... now at a quicker pace.</p>
            <p v-if="details.level==2" class="text-white text-opacity-80 text-xs font-h-monkey px-12 mb-5">Catch the bad virus, avoiding the white ones and be careful to not let any pass through!</p>

            <nav>
                <button @click="nextLevel" class="btn text-xl font-light mr-1 animate-elastic">Next Level</button>
            </nav>
        </div>

        <div v-else class="text-center" data-aos="zoom-in" data-aos-delay="600">
            <h1 class="font-6809 text-4xl uppercase mb-6">Level 1</h1>
            <p class="text-white text-opacity-80 text-sm font-h-monkey px-12 mb-5">Catch the bad virus, avoiding the white ones.</p>

            <nav>
                <button @click="letsStart" class="btn text-lg font-light mr-1 animate-elastic">Start</button>
                <button @click="goTo('intro')" class="btn btn-purple text-lg font-light ml-1 mt-4 animate-elastic">Go Back</button>
            </nav>
        </div>
		
        <footer class="flex justify-between border-t w-full mt-auto text-xs p-2">
			<img src="@/assets/images/baggl_logo.png" class="h-4">
			<span>barter. haggle. baggl.com</span>
		</footer>

    </div>

    <div v-else class="w-full h-full bg-cover bg-center" :class="`bg-${details.level}`">
        <div class="bg-primary bg-opacity-70">
            <header class="absolute top-0 left-0 p-5 text-sm w-full flex items-center justify-between">
                <h1 class="font-6809">SCORE: {{ details.score }}</h1>
                <nav class="lives-bar">
                    <span :class="{ visible: details.lives >= 1 }"></span>
                    <span :class="{ visible: details.lives >= 2 }"></span>
                    <span :class="{ visible: details.lives >= 3 }"></span>
                </nav>
            </header>
            <transition name="zoom">
                <h1 v-if="msg" class="game-msg" v-html="msg"></h1>
            </transition>
            <game :details="details" v-on:update-score="onUpdateScore" v-on:close-game="onCloseGame"/>
        </div>
    </div>

</template>

<style lang="scss" scoped>
.bg-1{
    background-image: url("../assets/images/bg_1.jpg");
}

.bg-2{
    background-image: url("../assets/images/bg_2.jpg");
}

.bg-3{
    background-image: url("../assets/images/bg_3.jpg");
}

 .game-msg{
    position: absolute;
    top: 20%;
    left: 0;
    color: white;
    text-transform: uppercase;
    z-index: 20;
    text-align: center;
    width: 100%;
    font-size: 1.3rem;
    padding: 0 15%;
    pointer-events: none;
    font-family: "Chargen";
    span{
      opacity: .5;
      font-size: 0.8em;
    }
  }

.lives-bar{
    display: flex;
    span{
        width: 10px;
        height: 10px;
        background: rgba(#fff, 0.15);
        border-radius: 50%;
        margin: 0 2px;
        &.visible{
            background: rgba(#fff, 1);
        }
    }
}
</style>

<script>
import game from './components/game'

export default {

    components: { game },

    data()
    {
        return{
            introgame: true,
            startgame: false,
            gameover: false,
            levelcomplete: false,
            msg: null,
            details: {
                audio: this.$userSession.audio,
                score: 0,
                lives: 3,
                level: 1
            }
        }
    },

	mounted(){
		this.$ua.trackView('game', true);
	},

    methods: {

        goTo(component, data = Array)
        {
            this.$emit('upcomponent', component, data)
        },

        letsStart(){
            this.startgame = true
            this.onMsgUpdate('hold and swipe the syringe to start', 3000);
        },

        onUpdateScore(d)
        {
            this.details.score = d.score;
            this.details.lives = d.lives;

            if(d.score <= 0) this.details.score = 0;
            if(d.lives <= 0) {
                this.startgame = false
                this.gameover = true
                this.goTo('gameover', { score: this.details.score })
                return
            }

            if(d.score > 150 && d.lives > 0 && this.details.level == 1){
                this.startgame = false
                this.levelcomplete = true
                this.details.lives = 3
                return
            }

            if(d.score > 500 && d.lives > 0 && this.details.level == 2){
                this.startgame = false
                this.levelcomplete = true
                this.details.lives = 3
                return
            }

            if(d.score > 1000 && d.lives > 0 && this.details.level == 3){
                //this.startgame = false
                //this.levelcomplete = true
                //return
            }

            if(d.msg){
                this.onMsgUpdate(d.msg)
            }
        },

        nextLevel(){
            this.details.level++
            this.startgame = true
        },

        onMsgUpdate(msg, timeout = 2000){
            this.msg = msg
            setTimeout(() => {
                this.msg = null
            }, timeout);
        },

        onCloseGame()
        {   
           // this.$logAction('click','doodle/close');

            //this.introgame = true;
            //this.startgame = false;
            //this.gameover = false;
            // this.goTo('games.doodle');
        },

        clickParticipateButton()
        {
            //this.$logAction('click','doodle/participate');

           // var finalscore = this.score;
           // this.goTo('participate', { score: finalscore, game: 'doodle' });
        },

        clickLeaderBoardButton()
        {
            //this.$logAction('click','doodle/leaderboard');

           // var finalscore = this.score;
           // this.goTo('leaderboard', { score: finalscore, game: 'doodle' });
        }

    }
}
</script>