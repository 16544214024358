import { render, staticRenderFns } from "./game.vue?vue&type=template&id=6fb39ade&scoped=true&"
import script from "./game.vue?vue&type=script&lang=js&"
export * from "./game.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fb39ade",
  null
  
)

export default component.exports