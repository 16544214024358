<template>
  <div id="app" class="bg-purple vh100" v-if="portrait == true && mobile == true">

      <transition name="component-fade" mode="out-in">
          <component :is="dynamicComponent" :data="data" @upaudio="upaudio" @upcomponent="upcomponent"></component>
      </transition>

      <div v-if="dynamicComponent != 'game.index'" class="text-secondary absolute top-0 right-0 m-2 text-sm">
          <i @click="upaudio({ stop: true })" v-if="is_audio" class="fas fa-volume-up"></i>
          <i @click="upaudio({ start: true })" v-if="!is_audio" class="fas fa-volume-mute"></i>
      </div>

      <audio ref="audio" class="hidden">
         <source src="@/assets/sounds/204_full_8bit-adventure_0155.mp3" type="audio/mpeg">
         Your browser does not support the audio element.
      </audio>

  </div>
  <div class="bg-primary bg-virus bg-animate flex flex-col justify-center items-center h-screen w-screen" v-else>

        <div class="relative mb-10">
            <img src="@/assets/images/monster_1.png" data-aos="fade-up" class="absolute w-32 -right-6 -top-5">
            <img src="@/assets/images/virus_slayer_logo.png" data-aos="fade-down" class="w-64" alt="logo">
        </div>

		<h1 v-if="!mobile" class="text-white font-6809 font-bold text-3xl">Mobile Only</h1>
		<h1 v-else class="text-xl font-6809">Please Rotate Device</h1>

		<img v-if="!mobile" src="https://query.appetitecreative.com/v2/public/qr?url=https://game.baggl.com/" class="w-40 mt-4" alt="">
		<p v-if="!mobile" class="uppercase opacity-50 font-bold mt-5 text-center text-sm">Switch to mobile <br>and start the experience!</p>

  </div>
</template>

<script>
export default {
    data(){
        return{
            debug: false,
            isLoading: false,
            dynamicComponent: 'intro',
            data: {},
            shared: {},
            audioRef: {},
			mobile: true,
			portrait: true,
            is_audio: false
        }
    },
    methods: {
        upcomponent(name, array = null) {
            this.dynamicComponent = name
            this.data = array
        },
        upaudio(obj) {
            
            this.audioRef = this.$refs.audio;
            this.audioRef.volume = obj.volume ? obj.volume : 0.5;

            if(obj.start == true) {
                this.is_audio = true
                this.$userSession.audio = true
                this.audioRef.play()
            }

            if(obj.stop == true) {
                this.is_audio = false
                this.$userSession.audio = false
                this.audioRef.pause()
                //audio.currentTime = 0
            }

            this.onMinimize();

        },
		onResize(){
			
			if(window.innerWidth > window.innerHeight){
				this.portrait = false;
			} else {
				this.portrait = true;
			}

			if(window.innerWidth > 767){
				this.mobile = false;
			} else {
				this.mobile = true;
			}

		},
        onMinimize(){
            window.onfocus = () => { 
                this.audioRef.play();
            };
            window.onblur = () => {
                this.audioRef.pause();
            };
        }
    },
    mounted()
    {
        this.onResize();

        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        // We listen to the resize event
        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            this.onResize();
        });

        // on unload
        window.onbeforeunload = () => {
            this.is_audio = false
            this.$userSession.audio = false
            this.audioRef.pause()
            this.audioRef.currentTime = 0
        }
    }
}
</script>