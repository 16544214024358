<template>
    <div>
        <p class="text-sm mx-4 py-4">If you’re playing to win, we need to collect a few bits of information:</p>
        <form class="px-8" method="post" @submit.prevent="submitForm">
            <div class="flex">
                <input type="text" required v-model="form.fname" placeholder="First Name" class="input w-full mr-3 text-secondary">
                <input type="text" required v-model="form.lname" placeholder="Last Name" class="input w-full text-secondary">
            </div>
            <div class="flex">
                <input type="email" required v-model="form.email" placeholder="E-mail" class="input w-full mt-3 text-secondary">
            </div>
            <nav>
                <button type="submit" class="btn text-base font-light mt-4 animate-elastic">Play</button>
                <button @click.prevent="goBack" class="btn btn-purple text-base font-light ml-3 mt-4 animate-elastic">Go Back</button>
                <p class="text-xxs mt-3">By submitting you agree with our Terms of Use.</p>
            </nav>
        </form>
    </div>
</template>

<script>
export default {
    data(){
        return{
			form: {
				fname: '',
				lname: '',
				email: ''
			},
        }    
    },
    methods: {
        goBack(){
            this.$emit('goback')
        },
        submitForm(){
            this.$userSession.form.name = this.form.fname + ' ' + this.form.lname;
			this.$userSession.form.email = this.form.email;

            var Filter = require('bad-words'),
                filter = new Filter();

            if(filter.isProfane(this.$userSession.form.name) || filter.isProfane(this.$userSession.form.email)){
                alert('No bad words!');
                return;
            }

            this.$emit('formsubmitted')
        }
    }
}
</script>

<style>

</style>