<template>

    <div class="bg-primary bg-virus w-full h-full flex flex-col items-center">

		<div class="relative mt-6">
			<img src="@/assets/images/monster_1.png" data-aos="fade-up" class="absolute w-32 -right-6 -top-5">
			<img src="@/assets/images/virus_slayer_logo.png" data-aos="fade-down" class="w-64" alt="logo">
		</div>

        <div class="w-full text-center mt-10">
            <h1 class="text-2xl uppercase font-6809">Top Scorers</h1>
            <ul v-if="!loading" class="bg-secondary bg-opacity-30 rounded-sm font-6809 mx-4 p-2">
                <li v-for="user in leaderboard" :key="user.id" class="flex justify-between w-full text-sm">
                    <p class="w-6/12 text-left">{{ user.name }}</p>
                    <p class="w-3/12 text-right">..........</p>
                    <span class="w-3/12 text-right">{{ user.score }}</span>
                </li>
            </ul>
            <p v-else class="bg-secondary bg-opacity-30 text-sm rounded-sm font-6809 mx-4 p-2 animate-pulse">
				<svg class="animate-spin mx-auto h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
			</p>
            <button @click="goTo('intro')" class="btn text-xl font-light mt-4 animate-elastic">Go Back</button>
        </div>

        <footer class="flex justify-between border-t w-full mt-auto text-xs p-2">
			<img src="@/assets/images/baggl_logo.png" class="h-4">
			<span>barter. haggle. baggl.com</span>
		</footer>

    </div>

</template>

<script>
import api from '../api'

export default {
    props: ['data'],
	data(){
		return{
			leaderboard: [],
            loading: true
		}
	},

	mounted(){
		this.$ua.trackView('leaderboard', true);
	},

    beforeMount(){
        api.leaderboard({ key: 'MkThw24PDVcQJcpTuRNb1IOMAi87neOd', limit: 10 }).then(r => {
            this.leaderboard = r.data.results
            this.loading = false
        })
    },

	methods: {
        goTo(component){
            this.$emit('upcomponent', component)
        }
	}
}
</script>